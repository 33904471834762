// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-page-index-js": () => import("./../src/pages/IndexPage/index.js" /* webpackChunkName: "component---src-pages-index-page-index-js" */),
  "component---src-pages-index-page-index-page-jsx": () => import("./../src/pages/IndexPage/IndexPage.jsx" /* webpackChunkName: "component---src-pages-index-page-index-page-jsx" */),
  "component---src-pages-not-found-page-index-js": () => import("./../src/pages/NotFoundPage/index.js" /* webpackChunkName: "component---src-pages-not-found-page-index-js" */),
  "component---src-pages-not-found-page-not-found-page-jsx": () => import("./../src/pages/NotFoundPage/NotFoundPage.jsx" /* webpackChunkName: "component---src-pages-not-found-page-not-found-page-jsx" */)
}

